export const ASSETS_CONSTANTS = [
  {
    key: 'baseImage',
    title: 'Base Image',
    parent: 'assets',
    color: '#FFD700',
  },
  {
    key: 'colorImage',
    title: 'Color Image',
    parent: 'assets',
    color: '#E9967A',
  },
  {
    key: 'poseImage',
    parent: 'assets',
    title: 'Pose Image',
    color: '#34b58a',
  },
  {
    key: 'structureImage',
    parent: 'assets',
    title: 'Structure Image',
    color: '#3461b5',
  },
  {
    key: 'softedgeImage',
    parent: 'assets',
    title: 'Sketch Image',
    color: '#b53481',
  },
];

export const ACTION_CONSTANTS = [
  {
    key: 'baseImage',
    title: 'Base',
    parent: 'assets',
    description:
      'Use this when you want the model to generate based on the content of the image.',
    image: '/images/dashboard/settings/base.png',
    color: '#FFD700',
  },
  {
    key: 'colorImage',
    title: 'Color',
    parent: 'assets',
    description:
      'Use this when you want the model to generate based on the content of the image.',
    image: '/images/dashboard/settings/color.png',
    color: '#E9967A',
  },
  {
    key: 'poseImage',
    parent: 'assets',
    description:
      'Use this when you want the model to generate based on the content of the image.',
    image: '/images/dashboard/settings/pose.png',
    title: 'Pose',
    color: '#34b58a',
  },
  {
    key: 'structureImage',
    parent: 'assets',
    title: 'Structure',
    description:
      'Use this when you want the model to generate based on the content of the image.',
    image: '/images/dashboard/settings/structure.png',
    color: '#3461b5',
  },
  {
    key: 'softedgeImage',
    parent: 'assets',
    description:
      'Use this when you want the model to generate based on the content of the image.',
    title: 'Sketch',
    image: '/images/dashboard/settings/sketch.png',
    color: '#b53481',
  },
];

export const aspectRatio = [
  '1152x576',
  '1024x576',
  '864x576',
  '768x576',
  '576x576',
  '576x768',
  '576x860',
  '576x1048',
  '576x1152',
];

export const aspectRatioMap = {
  '1152x576': 'Landscape - 2:1',
  '1024x576': 'Landscape - 16:9',
  '864x576': 'Landscape - 3:2',
  '768x576': 'Landscape - 4:3',
  '576x576': 'Square - 1:1',
  '576x768': 'Portrait - 3:4',
  '576x860': 'Portrait - 9:16',
  '576x1048': 'Portrait - 4:3',
  '576x1152': 'Portrait - 2:1',
};

export const aspectMap = {
  '1152x576': '2:1',
  '1024x576': '16:9',
  '864x576': '3:2',
  '768x576': '4:3',
  '576x576': '1:1',
  '576x768': '3:4',
  '576x860': '9:16',
  '576x1048': '4:3',
  '576x1152': '2:1',
};
