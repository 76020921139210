import React, { useEffect } from 'react';
import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Img,
  Text,
  extractImage,
  pluralize,
  useDebounce,
  getDataIcons,
} from '@nex/labs';
import { formatDistanceToNow } from 'date-fns';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { useUserStore } from '@/state/useStore';
import { useGetArtboardsQuery } from '@/state/query/prompt';
import { FetchContainer } from '@/components/misc/fetch-container';

import Bookmark from '@nex/icons/svg/misc/structure.svg';

import { CreateCollectionModal } from '@/components/layout';

import styles from '../library.module.scss';

interface ArtBoardsProps {
  query?: string;
  bare?: boolean;
  isSearch?: boolean | string;
  limit?: number;
}

export const ArtBoards = ({ query, bare, isSearch, limit }: ArtBoardsProps) => {
  const [currentTab, setCurrentTab] = React.useState('my-artboards');
  const router = useRouter();

  useEffect(() => {
    const { view } = router.query;
    if (view) {
      setCurrentTab(view as string);
    }
  }, [router.query]);

  const [createNew, setCreateNew] = React.useState(false);
  const searchValue = useDebounce(query, 500);
  const { profile } = useUserStore();

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetArtboardsQuery(
      {
        search: searchValue as string,
        limit: limit || 15,
        workspaceId: profile?.organizationId,
        shared: currentTab === 'shared',
      },
      {
        enabled: !!profile?.organizationId,
      }
    );

  return (
    <div className="mt-1">
      <div className={styles.ConsoleWrapper}>
        <div className={styles.ConsoleRecentWorks}>
          <FetchContainer
            isLoading={isLoading}
            hasMore={hasNextPage}
            fetchNextPage={
              typeof limit === 'undefined' ? fetchNextPage : undefined
            }
            shouldBeEmpty={!data?.artboards?.length}
            emptyMessage={
              isSearch
                ? 'No artboards found matching your search'
                : 'You have no artboards yet. Add your first artboard!'
            }
          >
            {!isSearch && !bare && (
              <>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  gap={12}
                  alignItems="center"
                >
                  <span>
                    <Flex gap={4}>
                      <Bookmark width={14} className="opacity-80" />{' '}
                      <Heading.h5 weight={700}>Artboards</Heading.h5>
                    </Flex>
                  </span>

                  <CallToAction.a
                    size="xs"
                    className="h-fit"
                    variant="secondary"
                    href="/artboard/new"
                    leadingIcon={<img src={getDataIcons('add', '#000')} />}
                  >
                    New Artboard
                  </CallToAction.a>
                </Flex>
              </>
            )}

            {data?.artboards?.length ? (
              <div className={styles.ArtboardGrid}>
                {data?.artboards
                  ?.slice(0, limit || data?.artboards?.length)
                  ?.map((artboard, index) => (
                    <Link
                      href={`/artboard/${artboard?.id}`}
                      key={artboard?.id}
                      className={styles.ConsoleArtboardsArea__card}
                    >
                      {[
                        ...(artboard?.compositions?.[0]?.images ||
                          artboard?.group?.[0]?.generations ||
                          []),
                        ...Array.from({ length: 1 }),
                      ]
                        ?.slice(0, 1)
                        .map((img: { url: string }, index) => (
                          <Img
                            src={
                              img
                                ? extractImage(img)
                                : '/images/empty_image.png'
                            }
                            alt="artboard"
                            key={`${img?.url}-${index}`}
                            className="h-[200px] basis-[100%] w-full rounded-md object-cover object-center border-[1px] border-[var(--primary-gray)] border-solid"
                          />
                        ))}
                      <div
                        className={styles.ConsoleArtboardsArea__card__content}
                      >
                        <Heading.h5 weight={700}> {artboard?.title}</Heading.h5>
                        <Flex.Row gap={8} alignItems="center">
                          <Avatar
                            size={24}
                            alt={artboard?.creator?.name}
                            src={artboard?.creator?.photo}
                          />
                          <Text>
                            {artboard?.updatedAt && (
                              <Text.span
                                className="opacity-70 mt-1"
                                fontSize="12px"
                              >
                                Updated{' '}
                                {formatDistanceToNow(
                                  new Date(artboard?.updatedAt)
                                )}
                                {' ago'}
                              </Text.span>
                            )}
                          </Text>
                        </Flex.Row>
                        {artboard?.collaborators?.length ? (
                          <Flex.Row gap={8} alignItems="center">
                            {artboard?.collaborators
                              ?.slice(0, 8)
                              ?.map((clb: any, i: number) => {
                                return (
                                  <Avatar
                                    src={clb?.user?.photo}
                                    alt={clb?.user?.name}
                                    key={clb?.user?.id}
                                    size={20}
                                    outline
                                    style={{
                                      marginLeft: i === 0 ? '' : '-4px',
                                    }}
                                  />
                                );
                              })}{' '}
                            <Text
                              className="opacity-70 mt-1 ml-2"
                              fontSize="12px"
                            >
                              {pluralize(
                                'Collaborator',
                                artboard?.collaborators?.length
                              )}
                            </Text>
                          </Flex.Row>
                        ) : null}
                      </div>
                    </Link>
                  ))}

                <Link
                  href="/"
                  className={styles['ConsoleArtboardsArea__card--add']}
                >
                  <span>
                    <img src={getDataIcons('add')} />
                  </span>
                  <Text weight={600}>New Artboard</Text>
                </Link>
              </div>
            ) : null}
          </FetchContainer>

          <CreateCollectionModal
            show={createNew}
            onClose={() => setCreateNew(false)}
            type="create"
          />
        </div>
      </div>
    </div>
  );
};
