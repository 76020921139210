import type { PropsWithChildren } from 'react';
import React, { use, useEffect, useMemo, useState } from 'react';

import {
  CallToAction,
  Flex,
  SkipNavContent,
  SkipNavLink,
  Text,
  isPremiumUser,
  transitions,
  useHover,
  Tooltip,
  useFeedback,
} from '@nex/labs';
import { useOrganization, useOrganizationList, useUser } from '@clerk/nextjs';

import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import Folder from '@nex/icons/svg/dashboard/folder.svg';
import Home from '@nex/icons/svg/dashboard/home-line.svg';
import LifeBuoy from '@nex/icons/svg/dashboard/life-buoy-01.svg';
import Settings from '@nex/icons/svg/dashboard/settings-01.svg';
import RocketVerified from '@nex/icons/svg/blocks/rocket.svg';

import PlusIcon from '@nex/icons/svg/blocks/plus.svg';

import { useGlobalStore, useUserStore } from '@/state/useStore';

import { WorkspaceSwitcher } from './component/workspace-switcher';
import { CreateWorkspaceModal } from './component/create-workspace';
import { ConsoleNav } from './component/console-nav';
import { ConsoleNexBar } from './component/nex-bar';

import styles from './console-layout.module.scss';

export const ConsoleLayout: React.FC<
  {
    title?: string;
    bare?: boolean;
  } & PropsWithChildren
> = ({ children, title }) => {
  const router = useRouter();
  const [openNav, setOpenNav] = React.useState(false);
  const getActive = (href: string) => {
    return router.pathname === href ? styles.active : '';
  };
  const { setOverlayLoading, setModal } = useGlobalStore();
  const getSlideIn = transitions.useSlide();
  const { user } = useUser();

  const { profile } = useUserStore();

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setOpenNav(false);
    });
  }, []);

  useEffect(() => {
    const redirectTo = window.sessionStorage.getItem('redirectTo');

    if (redirectTo) {
      setOverlayLoading({
        loading: true,
        message: 'Preparing your artboard and Redirecting...',
      });

      if (profile?.id || user) {
        setTimeout(() => {
          Router.push(redirectTo);
          window.sessionStorage.removeItem('redirectTo');
          setOverlayLoading(false);
        }, 1200);
      }
    }
  }, [profile?.id, user]);

  useEffect(() => {
    if (typeof window !== 'undefined')
      document.documentElement.style.setProperty(
        '--feedback-area-left',
        'var(--sidebar-width)'
      );

    return () => {
      if (typeof window !== 'undefined')
        document.documentElement.style.setProperty(
          '--feedback-area-left',
          '0px'
        );
    };
  }, []);

  const { hoverProps } = useHover({
    onHoverStart: (e) => setOpenNav(true),
    onHoverEnd: (e) => setOpenNav(false),
  });

  return (
    <div className={`${styles.ConsoleLayout}`}>
      <SkipNavLink focusSelector="maincontent" />

      <motion.aside
        key="sidebar"
        className={classNames([
          styles.ConsoleLayoutAside,
          openNav && styles.openNav,
        ])}
        {...(hoverProps as any)}
        {...getSlideIn({ x: -3 })}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.ConsoleLayoutAsideLogo}
        >
          <Link href="/">
            <Flex gap={6}>
              <LogoMark />
              <Text.span casing="uppercase" className="opacity-80">
                {profile?.subscription?.tier || 'Nex Basic'}
              </Text.span>
            </Flex>
          </Link>
          {!isPremiumUser(profile) ? (
            <Link href="/settings/plans">
              <Text.span
                fontSize="11px"
                weight={700}
                casing="uppercase"
                className="opacity-80"
                color="var(--primary-theme)"
              >
                Upgrade
              </Text.span>
            </Link>
          ) : (
            <Tooltip tooltip="You are a Premium user">
              <RocketVerified
                style={{
                  width: '16px',
                }}
              />
            </Tooltip>
          )}
        </Flex>

        <Flex.Column gap={10} className={styles.ConsoleLayoutAsideMetaWrap}>
          <div className={(!openNav && 'hidden xxl:block') || ''}>
            <WorkspaceSwitcher
              onShowCreateWorkspace={() => {
                setModal({
                  type: 'createWorkspace',
                  props: {
                    title: 'Create Workspace',
                    size: 'md',
                    dismissible: true,
                  },
                });
              }}
            />
          </div>
          <div className={(!openNav && 'hidden xxl:block') || ''}>
            <CallToAction.a
              leadingIcon={<PlusIcon />}
              href="/artboard/new"
              size="block"
            >
              <Text.span weight={700}>Create Artboard</Text.span>
            </CallToAction.a>
          </div>
          <div className={(!openNav && 'block xxl:hidden') || 'hidden '}>
            <Link href="/artboard/new" className={getActive('/library')}>
              <PlusIcon
                height="35"
                className="[
                &>path
              ] text-[var(--primary-theme)] stroke-current
              ]"
              />
            </Link>
          </div>
          <Flex.Column gap={2} className={styles.ConsoleLayoutAsideNav}>
            <Link href="/" className={getActive('/')}>
              <Home className={styles.isStroke} /> <Text.span>Home</Text.span>
            </Link>
            <Link href="/library" className={getActive('/library')}>
              <Folder className={styles.isStroke} />{' '}
              <Text.span>Library</Text.span>
            </Link>

            <Link href="/settings" className={getActive('/settings')}>
              <Settings className={styles.isStroke} />{' '}
              <Text.span>Settings</Text.span>
            </Link>

            <Link href="/help" className={getActive('/help')}>
              <LifeBuoy className={styles.isStroke} />{' '}
              <Text.span>Help</Text.span>
            </Link>
          </Flex.Column>
        </Flex.Column>
        <Flex.Column
          gap={12}
          className={styles.ConsoleLayoutAsideUpsell}
          flex="1"
          justifyContent="flex-end"
        >
          {!isPremiumUser(profile) && <ConsoleNexBar profile={profile} />}
        </Flex.Column>
      </motion.aside>

      <button
        type="button"
        className={classNames([
          styles.ConsoleLayoutAsideOverlay,
          openNav && styles.openNav,
        ])}
        onClick={() => setOpenNav(false)}
      />
      <div className={styles.ConsoleLayoutMain}>
        <div className={styles.ConsoleLayoutMainNav}>
          <ConsoleNav title={title} openSidebar={setOpenNav} />
        </div>

        <motion.main
          {...getSlideIn({ x: 3 })}
          className={styles.ConsoleLayoutMainContent}
        >
          <SkipNavContent focusSelector="maincontent">
            <div className={styles.MainContent}>{children}</div>
          </SkipNavContent>
        </motion.main>
      </div>
    </div>
  );
};
