import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Router from 'next/router';
import queryString from 'query-string';
import {
  CallToAction,
  Field,
  Flex,
  Img,
  Text,
  capitalize,
  cleanText,
  extractImage,
  useMediaQuery,
  getDataIcons,
} from '@nex/labs';

import classNames from 'classnames';

import { motion } from 'framer-motion';

import PresetIcon from '@nex/icons/svg/blocks/preset.svg';
import PromptIcon from '@nex/icons/svg/blocks/prompt.svg';

import { useArtboardStore } from '@/state/useStore';
import { SketchSettings } from '../sketch/components/sketch-settings';
import { useArtboardGenerate } from '../../hooks/useArtboardGenerate';

import styles from './preview.module.scss';
import { useFabric } from '@/components/layout';

export const ArtboardAsidePreview = () => {
  const {
    blockInView,
    layoutBar,
    remix,
    setLayoutBar,
    removeBlock,
    setActiveTab,
    resetBlocks,
    isGenerating,
    setModal,
  } = useArtboardStore();
  const { isSketch } = useFabric();

  const normalizeGenerate = useArtboardGenerate();
  const MIN_WIDTH = 50;
  const MAX_WIDTH = 220;

  const [width, setWidth] = useState(MAX_WIDTH); // Initial width

  const [snap, setSnap] = useState(false);

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const shouldShow = useMemo(() => width > MIN_WIDTH * 2, [width]);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (sidebarRef.current) {
        const newWidth = Math.abs(
          sidebarRef.current.getBoundingClientRect().right - e.clientX
        );

        setWidth((prev) => {
          if (Math.abs(newWidth - MAX_WIDTH) < 5) {
            setSnap(true);
            setTimeout(() => setSnap(false), 100);
            return MAX_WIDTH;
          }
          return Math.min(Math.max(newWidth, MIN_WIDTH), MAX_WIDTH);
        });
      }
    },
    [MIN_WIDTH, MAX_WIDTH]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, handleMouseUp]);

  const isMobile = useMediaQuery('lg', 'greaterThan');

  useEffect(() => {
    document.body.style.setProperty(
      '--preview-width',
      `${!isSketch ? 0 : width}px`
    );
  }, [width, isSketch]);

  const PREVIEW_VIEWS = {
    prompt: (block: any) => (
      <Flex.Column className={shouldShow ? 'p-3' : 'p-1'} gap={8}>
        <Flex.Row gap={8} alignItems="center">
          <Flex
            gap={8}
            alignItems="center"
            className="border-[1px] border-solid border-[#0000001A]  rounded-sm h-[48px] w-[48px]"
          >
            <PromptIcon className="w-[24px] h-[24px] m-auto" />
          </Flex>

          {shouldShow && (
            <Flex.Column gap={1}>
              {block.data?.subMeta && (
                <Text
                  weight={600}
                  casing="capitalize"
                  fontSize="12px"
                  className="opacity-70"
                >
                  {capitalize(block.data?.subMetaInfo?.title || block?.meta)}
                </Text>
              )}
              <Text weight={700} casing="capitalize" className="opacity-70">
                {cleanText(block.meta)}
              </Text>
            </Flex.Column>
          )}
        </Flex.Row>
        {shouldShow && (
          <Field.Textarea
            rows={3}
            value={block.data}
            disabled
            placeholder="Add your prompt"
          ></Field.Textarea>
        )}
      </Flex.Column>
    ),

    assets: (block: any) => (
      <Flex.Row
        gap={8}
        className={shouldShow ? 'p-3' : 'p-1'}
        alignItems="center"
      >
        <Img
          src={block.data?.buffer ?? block.data.src}
          alt={block.data.name}
          className="rounded-md w-[60px] h-[40px] object-cover"
        />

        {shouldShow && (
          <Flex.Column gap={1}>
            <Text weight={600} casing="capitalize" className="opacity-70">
              {cleanText(block.data?.subMetaInfo?.useAs || block.meta)}
            </Text>

            <Text weight={800} noOfLines={1}>
              <Flex gap={6} alignItems="center">
                {block.data?.subMetaInfo?.title && (
                  <span
                    className="rounded-full w-2 h-2"
                    style={{
                      backgroundColor: block.data?.subMetaInfo?.color || '#000',
                    }}
                  />
                )}

                {capitalize(
                  block.data?.subMetaInfo?.title ||
                    block.data.name ||
                    block?.meta
                )}
              </Flex>
            </Text>
          </Flex.Column>
        )}
      </Flex.Row>
    ),
  };

  const previewComponents = (meta: string, block: any) => {
    switch (meta) {
      case 'prompt':
      case 'negative_prompt':
        return PREVIEW_VIEWS['prompt'](block);
      default:
        return PREVIEW_VIEWS['assets'](block);
    }
  };
  // TODO: Accomodate for the new Bing Design
  if (!isSketch) return null;

  return (
    <>
      <div
        className={classNames([
          styles.ArtboardAsidePreviewOverlay,
          layoutBar === 'preview' && styles.isShow,
        ])}
        onClick={() => setLayoutBar('')}
      />

      <motion.aside
        ref={sidebarRef}
        className={classNames([
          styles.ArtboardAsidePreview,
          snap && styles.snap,
          layoutBar === 'preview' && styles.isShow,
        ])}
        id="artboard-preview"
      >
        {isSketch ? (
          <SketchSettings />
        ) : (
          <>
            <Flex.Column
              gap={18}
              justifyContent="space-between"
              className="h-full"
            >
              <Flex.Column gap={4}>
                <Flex.Row
                  gap={8}
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-3"
                >
                  <Flex gap={4} alignItems="center">
                    <button
                      className={
                        shouldShow
                          ? 'flex items-center gap-2'
                          : 'flex items-center justify-center gap-2'
                      }
                      onClick={() => setWidth(MAX_WIDTH)}
                    >
                      <PresetIcon
                        {...(!shouldShow
                          ? { width: 24, height: 24 }
                          : { width: 16, height: 16 })}
                      />
                    </button>
                    {shouldShow && <Text weight={600}>Blocks Preview</Text>}
                  </Flex>
                  {shouldShow && !!blockInView?.length && (
                    <button onClick={resetBlocks}>
                      <Text weight={600}>Reset</Text>
                    </button>
                  )}
                </Flex.Row>
                {shouldShow && (
                  <Flex.Column gap={8}>
                    {remix && (
                      <div className={styles.Remix}>
                        <Flex.Row gap={8} alignItems="center">
                          <Img
                            src={extractImage(remix?.images?.[0])}
                            alt={remix?.name}
                            className="rounded-md w-[60px] h-[60px] object-cover"
                          />
                          <Flex.Column gap={1}>
                            <Text weight={700}>Remixing Block</Text>
                            <Text
                              weight={600}
                              casing="capitalize"
                              className="opacity-70"
                            >
                              Built with {Object.keys(remix.blocks).length}{' '}
                              blocks
                            </Text>
                          </Flex.Column>
                        </Flex.Row>
                      </div>
                    )}
                    {blockInView?.length ? (
                      blockInView.map((block: any, index: number) => (
                        <div key={index} className={styles.BlockPreview}>
                          {previewComponents(block.meta, block)}
                          {shouldShow && (
                            <Flex.Row
                              justifyContent="flex-end"
                              alignItems="center"
                              gap={8}
                              className="border-t-[1px] p-1 border-solid border-[#EAECF0]"
                            >
                              <CallToAction.button
                                size="xs"
                                variant="clear"
                                onClick={() => {
                                  switch (
                                    block.data?.subMetaInfo?.useAs ||
                                    block.meta
                                  ) {
                                    case 'prompt':
                                    case 'negative_prompt':
                                      setActiveTab('prompt');
                                      break;
                                    case 'assets':
                                      setModal({
                                        type: 'crop',
                                        props: {
                                          title: `Edit ${block?.data?.subMetaInfo?.title}`,
                                          size: 'lg',
                                          blockId: block?.data?.subMeta,
                                        },
                                      });
                                      break;
                                    case 'sketch':
                                      setActiveTab('sketch');
                                      if (block?.data?.sketchId) {
                                        Router.push(
                                          queryString.stringifyUrl(
                                            {
                                              url: Router.asPath,
                                              query: {
                                                sketchId: block?.data?.sketchId,
                                              },
                                            },
                                            {
                                              skipEmptyString: true,
                                              skipNull: true,
                                            }
                                          )
                                        );
                                      }
                                      break;
                                    default:
                                      setActiveTab(block.meta);
                                      break;
                                  }
                                }}
                              >
                                Edit Block
                              </CallToAction.button>
                              <CallToAction.button
                                size="xs"
                                variant="clear"
                                onClick={() =>
                                  removeBlock(block.meta, block.data?.subMeta)
                                }
                              >
                                <img src={getDataIcons('bin', '#000')} />
                              </CallToAction.button>
                            </Flex.Row>
                          )}
                        </div>
                      ))
                    ) : (
                      <Flex.Column gap={4} alignItems="center" className="mt-8">
                        {shouldShow && (
                          <>
                            <PresetIcon
                              width="24"
                              height="24"
                              className="opacity-80 stroke-[1px] [&>path]:stroke-[1px]"
                            />
                            <Text
                              align="center"
                              className="opacity-80 max-w-[220px] m-auto"
                              weight={600}
                            >
                              Add blocks to create your composition
                            </Text>
                          </>
                        )}
                      </Flex.Column>
                    )}
                  </Flex.Column>
                )}
              </Flex.Column>

              <Flex.Column gap={4} className="w-full">
                {isMobile && (
                  <CallToAction.button
                    size="xs"
                    onClick={() => setLayoutBar('block')}
                    variant="secondary"
                    className="w-full"
                  >
                    Add Block
                  </CallToAction.button>
                )}
                {shouldShow && !!blockInView?.length && (
                  <CallToAction.button
                    size="xs"
                    onClick={normalizeGenerate}
                    variant={isMobile ? 'primary' : 'secondary'}
                    isLoading={isGenerating}
                    className="w-full"
                  >
                    Generate
                  </CallToAction.button>
                )}
              </Flex.Column>
            </Flex.Column>
            <button
              className={styles.ResizeHandler}
              onMouseDown={handleMouseDown}
              onClick={() =>
                setWidth(width === MIN_WIDTH ? MAX_WIDTH : MIN_WIDTH)
              }
            ></button>
          </>
        )}
      </motion.aside>
    </>
  );
};
