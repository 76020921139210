import React, { useCallback, useEffect } from 'react';
import Router from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { convertDataURIToBuffer, useFeedback } from '@nex/labs';
import {
  useArtboardStore,
  useGlobalStore,
  useUserStore,
} from '@/state/useStore';
import {
  useCreateArtBoardMutation,
  useGenerateFromCompositionMutation,
} from '@/state/query/block';
import queryString from 'query-string';

import { useFabric } from '@/components/layout';
import { useUser } from '@clerk/nextjs';

export const useArtboardGenerate = () => {
  const { profile } = useUserStore();
  const {
    setLayoutBar,
    blockInView,
    defaultConfig,
    setAuthModal,
    setIsGenerating,
  } = useArtboardStore();
  const { setModal } = useGlobalStore();

  const posthog = usePostHog();

  const { isSketch, handleCanvasChange } = useFabric();
  const { createToast } = useFeedback();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { mutateAsync: createArtBoard } = useCreateArtBoardMutation();
  const { mutateAsync: generate, isLoading: isGenerating } =
    useGenerateFromCompositionMutation({
      onError: (err: any) => {
        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });

        if (err?.code === 'NEX:INSUFFICIENT_CREDIT') {
          setModal({
            type: 'pricing',
            props: {
              size: 'xlg',
            },
          });
        }
      },
    });

  useEffect(() => {
    setIsGenerating(isGenerating);
  }, [isGenerating]);

  const normalizeGenerate = async () => {
    if (!user && isUserLoaded) {
      setAuthModal(true);
      return;
    }

    // setShowDrawer(false);
    setLayoutBar('');
    if (Router?.query?.id === 'new') {
      try {
        const res = await createArtBoard({
          title: 'Untitled',
          description: 'Untitled',
        });

        const finalUrl = queryString.stringifyUrl(
          {
            url: `/artboard/${res?.artboard?.id}`,
            query: {
              ...Object.keys(Router.query).reduce((acc, key) => {
                if (key === 'id') return acc;
                return { ...acc, [key]: Router.query[key] };
              }, {}),
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        );

        if (res?.artboard?.id) {
          await Router.replace(finalUrl, finalUrl, { shallow: true });
        }
      } catch (error) {
        createToast({
          message: 'Something went wrong',
          variant: 'error',
        });
      }
    }

    const getValues = blockInView.reduce((acc, block) => {
      if (block.meta === 'preset') {
        return {
          ...acc,
          [`${block.meta}Id`]: block.data.id,
        };
      }

      const isDataUrl = (str: string) => {
        return str?.includes('data:image');
      };
      const convertToBuffer = (data: any) => {
        if (isDataUrl(data)) {
          return convertDataURIToBuffer(data);
        }

        return data;
      };

      if (block.data?.subMeta) {
        const hasUse = block.data?.use;

        return {
          ...acc,
          [block.data?.subMeta]: {
            [hasUse ? 'use' : isDataUrl(block.data?.src) ? 'buffer' : 'key']:
              convertToBuffer(block?.data?.key || block.data.src),
            ...((block.data.sketchId && {
              id: block.data.sketchId,
              type: 'sketch',
            }) || {
              type: 'asset',
            }),
            ...((block.data?.buffer && {
              use: convertToBuffer(block.data.buffer),
            }) ||
              {}),
            weight: block.data.weight || 0.5,
          },
        };
      }

      return {
        ...acc,
        [block.meta]: block.data,
      };
    }, {});

    if (!!!Object?.values(getValues).join('')) {
      createToast({
        message: 'Please add values to your blocks to generate',
        variant: 'error',
      });
      return;
    }

    const finalValues = {
      ...getValues,
      modelId: defaultConfig.model?.id,
      size: +defaultConfig.size,
      aspectRatio: defaultConfig.resolution.split('x'),
      raw: defaultConfig.raw,
    };

    posthog.capture('blocks_generation_started', {
      tref: window.sessionStorage.getItem('tref') || '',
      blocks: finalValues,
    });

    await generate({
      data: finalValues,
      workspaceId: profile?.organizationId,
      artboardId: Router.query?.id,
    });

    if (isSketch) {
      handleCanvasChange('');
    }
  };

  return normalizeGenerate;
};
