import { useGlobalStore } from '@/state/useStore';
import { useFeedback } from '@nex/labs';

export const useFileUpload = () => {
  const { setAssets, assets, setModal, modal } = useGlobalStore();
  const { createDisclosure } = useFeedback();

  const onFileUpload = (files: any, options: any) => {
    if (!files || files.length === 0) return;

    if (modal.type !== 'assets') {
      setModal({
        type: 'assets',
        props: {
          title: 'Upload Image',
          size: 'lg',
          onClose: async () => {
            await createDisclosure({
              title: 'Upload Image',
              message: 'Are you sure you want to close this modal?',
              confirmText: 'Close',
            });

            setAssets([]);
          },
        },
      });
    }

    setAssets({
      ...assets,
      ...files.reduce((acc: any, file: File, index: number) => {
        const assetKey = file?.name || `image-${assets!.length}`;

        return {
          ...acc,
          [assetKey]: {
            file: file as any,
            preview: options.previews[index] as string,
          },
        };
      }, {}),
    });
  };

  return { onFileUpload };
};
