import React, { useCallback } from 'react';
import Router from 'next/router';
import classNames from 'classnames';

import {
  CallToAction,
  Field,
  Flex,
  Popover,
  Spinner,
  Tabs,
  Text,
  useFeedback,
  getDataIcons,
  Slider,
  Tooltip,
} from '@nex/labs';

import dynamic from 'next/dynamic';

import CircleIcon from '@nex/icons/svg/blocks/sketch/circle.svg';
import CursorIcon from '@nex/icons/svg/blocks/sketch/cursor.svg';
import SaveIcon from '@nex/icons/svg/blocks/sketch/save.svg';
import CanvasImage from '@nex/icons/svg/blocks/sketch/canvas-image.svg';
import TriangleIcon from '@nex/icons/svg/blocks/sketch/triangle.svg';
import RectangleIcon from '@nex/icons/svg/blocks/sketch/rectangle.svg';
import FreeHandIcon from '@nex/icons/svg/blocks/sketch/freehand.svg';
import LinesIcon from '@nex/icons/svg/blocks/sketch/lines.svg';
import UndoIcon from '@nex/icons/svg/blocks/sketch/undo.svg';
import RedoIcon from '@nex/icons/svg/blocks/sketch/redo.svg';
import Image from '@nex/icons/svg/dashboard/image.svg';

import { useArtboardStore, useUserStore } from '@/state/useStore';

import { useDeleteSketchMutation } from '@/state/query/block';
import { ArtboardAssetsBlock } from '../../../../views/assets';
import { useFabric } from '@/components/layout';

import styles from './floating-nav.module.scss';
import { UseAsControl } from '@/components/misc/use-control';
import { ASSETS_CONSTANTS } from '@/features/console/artboard/utils/constants';

const Colorful = dynamic(
  () => import('@uiw/react-color').then((mod) => mod.Colorful),
  {
    ssr: false,
  }
);

const shapesConstants = [
  {
    value: 'rectangle',
    icon: RectangleIcon,
  },
  {
    value: 'circle',
    icon: CircleIcon,
  },

  {
    value: 'triangle',
    icon: TriangleIcon,
  },
  {
    value: 'line',
    icon: LinesIcon,
  },
];
const linesConstants = [
  {
    value: 'freeform',
    icon: FreeHandIcon,
  },
];

export const FloatingNav = ({
  activeElement,
  imageInputRef,
  handleImageUpload,
  hasHistory,
  hasRedoHistory,
  saveCanvas,
  handleActiveElement,
}: {
  activeElement: { value: string };
  imageInputRef: React.RefObject<HTMLInputElement>;
  hasHistory: boolean;
  hasRedoHistory: boolean;
  saveCanvas: () => void;
  handleImageUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    key?: string
  ) => void;
  handleActiveElement: (e: {
    value: string;
    image?: string;
    attributes?: { color: string; strokeWidth: number; type: string };
  }) => void;
}) => {
  const [currentTool, setcurrentTool] = React.useState({
    shape: shapesConstants[0],
    lines: linesConstants[0],
  });
  const [freeHand, setFreeHand] = React.useState({
    color: '#000',
    strokeWidth: 5,
    type: 'Pencil',
  });

  const [canvasBg, setCBg] = React.useState({
    color: '#ffffff',
    image: '',
  });

  const setCanvasBg = useCallback(
    async (data: any) => {
      setCBg(data);

      handleActiveElement({
        value: 'canvasBg',
        attributes: data,
      });
    },
    [handleActiveElement]
  );

  const { fabric, syncShapeInStorage } = useFabric();

  const { createDisclosure } = useFeedback();
  const { profile } = useUserStore();
  const { mutateAsync: deleteSketch, isLoading } = useDeleteSketchMutation();
  const {
    sketch: { activeObject },
    isRealTime,
    sketch,
    setBlock,
  } = useArtboardStore();
  const { handleCanvasChange } = useFabric();

  const saveSketch = useCallback(
    (block: any) => {
      setBlock('assets', {
        src: fabric.current?.toDataURL(),
        name: block.name,
        subMetaInfo: {
          ...ASSETS_CONSTANTS.find((item) => item.key === block),
          useAs: 'sketch',
        },
        use: true,
        weight: 0.5,
        sketchId: Router.query?.sketchId,
        subMeta: block,
      });

      syncShapeInStorage('save');
    },
    [sketch, fabric]
  );

  return (
    <Flex className={styles.SketchWrapper}>
      {isLoading && <Spinner fullPage />}
      <Flex flexWrap="wrap" gap={8} className={styles.Controls}>
        {Router.asPath.includes('/artboard') && (
          <>
            <button
              onClick={() => {
                handleCanvasChange('');
              }}
            >
              <Tooltip content="Back">
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  className="rotate-[90deg] w-[14px] h-[20px]"
                />
              </Tooltip>
            </button>

            <span />
          </>
        )}

        <button
          onClick={() => {
            handleActiveElement({
              value: 'select',
            });
          }}
          className={classNames({
            [styles.Active]: activeElement.value === 'select',
          })}
        >
          <Tooltip content="Cursor">
            <CursorIcon />
          </Tooltip>
        </button>

        <span />

        <Popover hug closeOnInteraction>
          <Popover.Trigger>
            <Tooltip content="Shapes">
              <button
                className={classNames({
                  [styles.Active]: shapesConstants.some((shape) => {
                    return activeElement?.value === shape.value;
                  }),
                })}
              >
                <currentTool.shape.icon />
              </button>
            </Tooltip>
          </Popover.Trigger>
          <Popover.Content>
            {shapesConstants.map((shape) => (
              <button
                onClick={() => {
                  handleActiveElement({
                    value: shape.value,
                  });
                  setcurrentTool((prev) => ({
                    ...prev,
                    shape,
                  }));
                }}
                className={classNames([
                  'flex flex-wrap items-center gap-2 px-3 py-2 font-bold capitalize text-[14px]',
                  styles.Active && activeElement.value === shape.value,
                ])}
                key={shape.value}
              >
                <shape.icon />
                {shape.value}
              </button>
            ))}
          </Popover.Content>
        </Popover>

        <Popover
          hug
          closeOnInteraction
          onClose={() => {
            handleActiveElement({
              value: 'freeform',
              attributes: freeHand,
            });
          }}
        >
          <Popover.Trigger>
            <Tooltip content="FreeHand">
              <button
                className={classNames({
                  [styles.Active]: activeElement.value === 'freeform',
                })}
                onClick={() => {
                  handleActiveElement({
                    value: 'freeform',
                  });
                }}
              >
                <FreeHandIcon />
              </button>
            </Tooltip>
          </Popover.Trigger>
          <Popover.Content>
            <Flex.Column gap={12} className="p-4">
              <Field.Select
                label={null}
                value={freeHand?.type}
                options={[
                  { label: 'Pencil', value: 'Pencil' },
                  { label: 'Circle', value: 'Circle' },
                  { label: 'Spray', value: 'Spray' },
                  { label: 'Pattern', value: 'Pattern' },
                ]}
                onChange={(e: any) => {
                  setFreeHand((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }));
                }}
              ></Field.Select>

              <Colorful
                color={freeHand.color}
                onChange={(color) => {
                  setFreeHand((prev) => ({
                    ...prev,
                    color: color.hexa,
                  }));
                }}
              />

              <div>
                <Text weight={600} className="mb-2">
                  Stroke Width
                </Text>
                <Slider
                  minValue={1}
                  maxValue={50}
                  value={freeHand.strokeWidth}
                  onChange={(value) => {
                    setFreeHand((prev) => ({
                      ...prev,
                      strokeWidth: value as number,
                    }));
                  }}
                />
              </div>
            </Flex.Column>
          </Popover.Content>
        </Popover>

        <Popover>
          <Popover.Trigger>
            <Tooltip content="Add Image">
              <button>
                <Image />
              </button>
            </Tooltip>
          </Popover.Trigger>
          <Popover.Content>
            <div className="p-3">
              <Tabs defaultTab="upload" size="sm">
                <Tabs.Tab dataKey="upload" title="Upload">
                  <button className="flex flex-wrap items-center relative cursor-pointer justify-center m-auto p-2">
                    <input
                      type="file"
                      className="absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer"
                      ref={imageInputRef}
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    <Flex.Column
                      gap={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image />
                      <Text weight={700}>Upload images</Text>
                      <Text>upload up to 10mb PNG/JPEG</Text>
                      <CallToAction.button
                        size="xs"
                        variant="secondary"
                        className="h-fit"
                        onClick={() => {
                          imageInputRef.current?.click();
                        }}
                      >
                        Choose File
                      </CallToAction.button>
                    </Flex.Column>
                  </button>
                </Tabs.Tab>
                <Tabs.Tab dataKey="assets" title="Assets">
                  <ArtboardAssetsBlock
                    inSketch
                    onAssetSelect={async (asset, key) => {
                      await handleImageUpload(
                        {
                          stopPropagation: () => {},
                          target: { files: [asset] },
                        } as any,
                        key
                      );
                    }}
                  />
                </Tabs.Tab>
              </Tabs>
            </div>
          </Popover.Content>
        </Popover>
        <Popover onClose={() => {}}>
          <Popover.Trigger>
            <Tooltip content="Canvas Background">
              <button>
                <CanvasImage />
              </button>
            </Tooltip>
          </Popover.Trigger>
          <Popover.Content>
            <div className="p-3">
              <Tabs defaultTab="color" size="sm">
                <Tabs.Tab dataKey="color" title="Color">
                  <Colorful
                    color={canvasBg.color}
                    onChange={(color) => {
                      setCanvasBg({
                        image: '',
                        color: color.hexa,
                      });
                    }}
                  />
                </Tabs.Tab>
                <Tabs.Tab dataKey="photo" title="Photos">
                  <ArtboardAssetsBlock
                    inSketch
                    onAssetSelect={async (asset, key) => {
                      setCanvasBg({
                        color: '',
                        image: `${process.env.NEXT_PUBLIC_CANVAS_HOST}/${key}`,
                      });
                    }}
                  />
                </Tabs.Tab>
              </Tabs>
            </div>
          </Popover.Content>
        </Popover>
        <span />

        <button
          onClick={() => {
            handleActiveElement({
              value: 'undo',
            });
          }}
          disabled={!hasHistory}
          className={!hasHistory ? 'opacity-50' : ''}
        >
          <Tooltip content="Undo">
            <UndoIcon />{' '}
          </Tooltip>
        </button>

        <button
          onClick={() => {
            handleActiveElement({
              value: 'redo',
            });
          }}
          disabled={!hasRedoHistory}
          className={!hasRedoHistory ? 'opacity-50' : ''}
        >
          <Tooltip content="Redo">
            <RedoIcon />
          </Tooltip>
        </button>

        {activeObject && (
          <>
            <span />

            <button
              className="ml-auto"
              onClick={() => {
                handleActiveElement({
                  value: 'delete',
                });
              }}
            >
              <Tooltip content="Delete">
                <img src={getDataIcons('bin', '#000')} />
              </Tooltip>
            </button>
          </>
        )}
        {!activeObject &&
          !!Router.query.sketchId &&
          Router.query.sketchId !== 'new' && (
            <>
              <span />
              <button
                className="ml-auto"
                onClick={async () => {
                  await createDisclosure({
                    title: 'Delete Sketch',
                    message: 'Are you sure you want to delete this sketch?',
                  });

                  await deleteSketch({
                    sketchId: Router.query.sketchId as string,
                    workspaceId: profile?.organizationId!,
                  });

                  handleCanvasChange(isRealTime ? 'new' : '');
                }}
              >
                Delete Sketch
              </button>
            </>
          )}

        <button className="ml-auto" onClick={saveCanvas}>
          Save
          <SaveIcon />
        </button>
      </Flex>
      <div className={styles.SketchUseAsWrapper}>
        <Popover size="sm" outSet="block" closeOnInteraction>
          <Popover.Trigger>
            <CallToAction.button size="sm" className="w-full min-w-[150px]">
              Use as
            </CallToAction.button>
          </Popover.Trigger>
          <Popover.Content isDropdown>
            <Text align="left" fontSize="13px" color="#000">
              Use this collage as
            </Text>

            <UseAsControl onSelect={(block) => saveSketch(block)} />
          </Popover.Content>
        </Popover>
      </div>
    </Flex>
  );
};
